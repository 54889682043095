import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import PageHeader from "../../components/common/PageHeader";
import { useXhr } from "hooks/useXhr";
import { useYieldContext } from "hooks/useYieldContext";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Button, Spinner } from "react-bootstrap";

function Yield() {
    //States
    const [item, setItem] = useState({
        firstPhoto: null,
        secondPhoto: null,
        thirdPhoto: null,
        alt: null,
        name1: null,
        isNative: "false",
        text1: null,
        type1: null,
        origin: null,
        size1: null,
        packaging: null,
        desc1: null,
        calories: null,
        totalFat: null,
        saturatedFat: null,
        transFat: null,
        cholesterol: null,
        sodium: null,
        totalCarbohydrate: null,
        dietaryFiber: null,
        totalSugars: null,
        protein: null,
        nameEng: null,
        textEng: null,
        typeEng: null,
        originEng: null,
        sizeEng: null,
        packagingEng: null,
        descEng: null,
        nameFra: null,
        textFra: null,
        typeFra: null,
        originFra: null,
        sizeFra: null,
        packagingFra: null,
        descFra: null,
        nameGer: null,
        textGer: null,
        typeGer: null,
        originGer: null,
        sizeGer: null,
        packagingGer: null,
        descGer: null,
        nameDut: null,
        textDut: null,
        typeDut: null,
        originDut: null,
        sizeDut: null,
        packagingDut: null,
        descDut: null
    });
    const [status, setStatus] = useState(false);
    const [isUpdate, setIsUpdate] = useState(null);
    const [isDelete, setIsDelete] = useState(null);
    const [isModal, setIsModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [lang, setLang] = useState(null);
    const [error, setError] = useState({
        field: null,
        note: null
    });

    //Hooks
    const { customXhr } = useXhr()
    const { yield1, dispatch } = useYieldContext();

    const requiredInsertFields = ["alt", "name1", "isNative", "firstPhoto", "secondPhoto", "thirdPhoto"];
    const requiredUpdateFields = ["alt", "name1", "isNative"];
    const maxItemLimit = 100;

    const handleTextInput = (e) => {
        setItem(prevState => ({
            ...prevState,
            desc1: e
        }));
    };

    const handleTextInputUpdate = (e) => {
        if (lang === "tur") {
            setIsUpdate(prevState => ({
                ...prevState,
                desc1: e
            }));
        } else if (lang === "eng") {
            setIsUpdate(prevState => ({
                ...prevState,
                descEng: e
            }));
        } else if (lang === "fra") {
            setIsUpdate(prevState => ({
                ...prevState,
                descFra: e
            }));
        } else if (lang === "ger") {
            setIsUpdate(prevState => ({
                ...prevState,
                descGer: e
            }));
        } else if (lang === "dut") {
            setIsUpdate(prevState => ({
                ...prevState,
                descDut: e
            }));
        }
    };

    const handleOnChange = (e) => {
        const { id, value } = e.target;

        setItem(prevState => ({
            ...prevState,
            [id]: value
        }));
        setError({
            field: null,
            note: null
        });
    };

    const handleOnUpdateChange = (e) => {
        const { id, value } = e.target;

        setIsUpdate(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const generateUniqueFileName = (file) => {
        const timestamp = Date.now();
        const randomNumber = Math.floor(Math.random() * 10000);
        const extension = file.name.split('.').pop();

        return `${timestamp}_${randomNumber}.${extension}`;
    };

    const handlePhotoInput = (e) => {
        const file = e.target.files[0];
        const id = e.target.id;

        const newFileName = generateUniqueFileName(file);
        const renamedFile = new File([file], newFileName, { type: file.type });

        setItem(prevState => ({
            ...prevState,
            [id]: renamedFile
        }));
        setError({
            field: null,
            note: null
        });
    };

    const handlePhotoUpdate = (e) => {
        const file = e.target.files[0];
        const id = e.target.id;

        const newFileName = generateUniqueFileName(file);
        const renamedFile = new File([file], newFileName, { type: file.type });

        setIsUpdate(prevState => ({
            ...prevState,
            [id]: renamedFile
        }));
    };

    const handleDelete = async (id) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('module', 'products_yield');
        formData.append('action', 'delete');
        formData.append('id', id);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setStatus(!status);
            setIsDelete(null);
            setIsLoading(false);
        };
    };

    const handleUpdate = async () => {
        for (const field of requiredUpdateFields) {
            if (!isUpdate[field] || isUpdate[field] === "") {
                return (
                    setError({
                        field: field,
                        note: `Zorunlu alan`
                    }),
                    setIsLoading(false)
                )
            }
        }
        setIsLoading(true);
        const formData = new FormData();
        formData.append('module', 'products_yield');
        formData.append('action', 'update');
        formData.append('id', isUpdate.id);
        formData.append('firstPhoto', isUpdate.firstPhoto);
        formData.append('secondPhoto', isUpdate.secondPhoto);
        formData.append('thirdPhoto', isUpdate.thirdPhoto);
        formData.append('alt', isUpdate.alt);
        formData.append('name1', isUpdate.name1);
        formData.append('isNative', isUpdate.isNative);
        formData.append('text1', isUpdate.text1);
        formData.append('type1', isUpdate.type1);
        formData.append('origin', isUpdate.origin);
        formData.append('size1', isUpdate.size1);
        formData.append('packaging', isUpdate.packaging);
        formData.append('desc1', isUpdate.desc1);
        formData.append('calories', isUpdate.calories);
        formData.append('totalFat', isUpdate.totalFat);
        formData.append('saturatedFat', isUpdate.saturatedFat);
        formData.append('transFat', isUpdate.transFat);
        formData.append('cholesterol', isUpdate.cholesterol);
        formData.append('sodium', isUpdate.sodium);
        formData.append('totalCarbohydrate', isUpdate.totalCarbohydrate);
        formData.append('dietaryFiber', isUpdate.dietaryFiber);
        formData.append('totalSugars', isUpdate.totalSugars);
        formData.append('protein', isUpdate.protein);
        formData.append('nameEng', isUpdate.nameEng);
        formData.append('textEng', isUpdate.textEng);
        formData.append('typeEng', isUpdate.typeEng);
        formData.append('originEng', isUpdate.originEng);
        formData.append('sizeEng', isUpdate.sizeEng);
        formData.append('packagingEng', isUpdate.packagingEng);
        formData.append('descEng', isUpdate.descEng);
        formData.append('nameFra', isUpdate.nameFra);
        formData.append('textFra', isUpdate.textFra);
        formData.append('typeFra', isUpdate.typeFra);
        formData.append('originFra', isUpdate.originFra);
        formData.append('sizeFra', isUpdate.sizeFra);
        formData.append('packagingFra', isUpdate.packagingFra);
        formData.append('descFra', isUpdate.descFra);
        formData.append('nameGer', isUpdate.nameGer);
        formData.append('textGer', isUpdate.textGer);
        formData.append('typeGer', isUpdate.typeGer);
        formData.append('originGer', isUpdate.originGer);
        formData.append('sizeGer', isUpdate.sizeGer);
        formData.append('packagingGer', isUpdate.packagingGer);
        formData.append('descGer', isUpdate.descGer);
        formData.append('nameDut', isUpdate.nameDut);
        formData.append('textDut', isUpdate.textDut);
        formData.append('typeDut', isUpdate.typeDut);
        formData.append('originDut', isUpdate.originDut);
        formData.append('sizeDut', isUpdate.sizeDut);
        formData.append('packagingDut', isUpdate.packagingDut);
        formData.append('descDut', isUpdate.descDut);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setIsUpdate(null);
            setLang(null);
            setStatus(!status);
            setError({
                field: null,
                note: null
            });
            setIsLoading(false);
        };
    };

    const handleSubmit = async () => {
        for (const field of requiredInsertFields) {
            if (!item[field] || item[field] === "") {
                return (
                    setError({
                        field: field,
                        note: `Zorunlu alan`
                    }),
                    setIsLoading(false)
                )
            }
        }
        if (yield1.length === maxItemLimit) return (alert("Maksimum veri sınırına ulaşıldı. Lütfen NovusID ekibinden destek talep ediniz."));
        setIsLoading(true);
        const formData = new FormData();
        formData.append('module', 'products_yield');
        formData.append('action', 'insert');
        formData.append('alt', item.alt);
        formData.append('firstPhoto', item.firstPhoto);
        formData.append('secondPhoto', item.secondPhoto);
        formData.append('thirdPhoto', item.thirdPhoto);
        formData.append('name1', item.name1);
        formData.append('isNative', item.isNative);
        formData.append('text1', item.text1);
        formData.append('type1', item.type1);
        formData.append('origin', item.origin);
        formData.append('size1', item.size1);
        formData.append('packaging', item.packaging);
        formData.append('desc1', item.desc1);
        formData.append('calories', item.calories);
        formData.append('totalFat', item.totalFat);
        formData.append('saturatedFat', item.saturatedFat);
        formData.append('transFat', item.transFat);
        formData.append('cholesterol', item.cholesterol);
        formData.append('sodium', item.sodium);
        formData.append('totalCarbohydrate', item.totalCarbohydrate);
        formData.append('dietaryFiber', item.dietaryFiber);
        formData.append('totalSugars', item.totalSugars);
        formData.append('protein', item.protein);
        formData.append('nameEng', item.nameEng);
        formData.append('textEng', item.textEng);
        formData.append('typeEng', item.typeEng);
        formData.append('originEng', item.originEng);
        formData.append('sizeEng', item.sizeEng);
        formData.append('packagingEng', item.packagingEng);
        formData.append('descEng', item.descEng);
        formData.append('nameFra', item.nameFra);
        formData.append('textFra', item.textFra);
        formData.append('typeFra', item.typeFra);
        formData.append('originFra', item.originFra);
        formData.append('sizeFra', item.sizeFra);
        formData.append('packagingFra', item.packagingFra);
        formData.append('descFra', item.descFra);
        formData.append('nameGer', item.nameGer);
        formData.append('textGer', item.textGer);
        formData.append('typeGer', item.typeGer);
        formData.append('originGer', item.originGer);
        formData.append('sizeGer', item.sizeGer);
        formData.append('packagingGer', item.packagingGer);
        formData.append('descGer', item.descGer);
        formData.append('nameDut', item.nameDut);
        formData.append('textDut', item.textDut);
        formData.append('typeDut', item.typeDut);
        formData.append('originDut', item.originDut);
        formData.append('sizeDut', item.sizeDut);
        formData.append('packagingDut', item.packagingDut);
        formData.append('descDut', item.descDut);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setItem({
                firstPhoto: null,
                secondPhoto: null,
                thirdPhoto: null,
                alt: null,
                name1: null,
                isNative: "false",
                text1: null,
                type1: null,
                origin: null,
                size1: null,
                packaging: null,
                desc1: null,
                calories: null,
                totalFat: null,
                saturatedFat: null,
                transFat: null,
                cholesterol: null,
                sodium: null,
                totalCarbohydrate: null,
                dietaryFiber: null,
                totalSugars: null,
                protein: null,
                nameEng: null,
                textEng: null,
                typeEng: null,
                originEng: null,
                sizeEng: null,
                packagingEng: null,
                descEng: null,
                nameFra: null,
                textFra: null,
                typeFra: null,
                originFra: null,
                sizeFra: null,
                packagingFra: null,
                descFra: null,
                nameGer: null,
                textGer: null,
                typeGer: null,
                originGer: null,
                sizeGer: null,
                packagingGer: null,
                descGer: null,
                nameDut: null,
                textDut: null,
                typeDut: null,
                originDut: null,
                sizeDut: null,
                packagingDut: null,
                descDut: null
            });
            setIsModal(null);
            setStatus(!status);
            setError({
                field: null,
                note: null
            });
            setIsLoading(false);
        };
    };

    const getYield = async () => {
        const formData = new FormData();
        formData.append('module', 'products_yield');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            dispatch({ type: 'SET_YIELD', payload: response.data });
        };
    };

    useEffect(() => {
        getYield();
    }, [status]);

    const appendThumbToFileName = (fileName) => {
        const dotIndex = fileName.lastIndexOf(".");
        if (dotIndex !== -1) {
            const newFileName = fileName.slice(0, dotIndex) + "_thumb" + fileName.slice(dotIndex);
            return newFileName;
        } else {
            console.log("Dosya uzantısı bulunamadı.");
            return null;
        }
    };

    //Columns
    var columnT = ""

    columnT = [
        {
            name: "ÜRÜN ADI",
            selector: (row) => row.name1,
            sortable: true,
            cell: (row) => <a href="#" className="fw-bold text-primary">{row.name1}</a>
        },
        {
            name: "FOTOĞRAFLAR",
            selector: (row) => row.photoNames,
            sortable: true,
            cell: row => <div className="row mediaPhotos">
                <div className="col-2" >
                    {row.firstPhotoName !== '' &&
                        <img src={process.env.PUBLIC_URL + `/uploads/products_yield/${appendThumbToFileName(row.firstPhotoName)}`} />
                    }
                </div>
                <div className="col-2" >
                    {row.secondPhotoName !== '' &&
                        <img src={process.env.PUBLIC_URL + `/uploads/products_yield/${appendThumbToFileName(row.secondPhotoName)}`} />
                    }
                </div>
                <div className="col-2" >
                    {row.thirdPhotoName !== '' &&
                        <img src={process.env.PUBLIC_URL + `/uploads/products_yield/${appendThumbToFileName(row.thirdPhotoName)}`} />
                    }
                </div>
            </div>,
            minWidth: "450px"
        },
        {
            name: "YERLİ Mİ?",
            selector: (row) => (row.isNative === "true" ? "Evet" : "Hayır"),
            sortable: true
        },
        {
            name: "GÜNCELLENDİ",
            selector: (row) => row.updatedAt,
            sortable: true
        },
        {
            name: "AKSİYON",
            selector: (row) => { },
            sortable: true,
            cell: (row) => <div className="btn-group" role="group" aria-label="Basic outlined example">
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    title="Türkçe Güncelleme"
                    onClick={() => { setIsUpdate(row); setLang("tur"); }}
                >
                    <i className="icofont-edit text-success"></i>
                </button>
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    title="İngilizce Güncelleme"
                    onClick={() => { setIsUpdate(row); setLang("eng"); }}
                >
                    <img src={process.env.PUBLIC_URL + `/flags/united-kingdom.png`} style={{ width: "15px" }} />
                </button>
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    title="Fransızca Güncelleme"
                    onClick={() => { setIsUpdate(row); setLang("fra"); }}
                >
                    <img src={process.env.PUBLIC_URL + `/flags/france.png`} style={{ width: "15px" }} />
                </button>
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    title="Almanca Güncelleme"
                    onClick={() => { setIsUpdate(row); setLang("ger"); }}
                >
                    <img src={process.env.PUBLIC_URL + `/flags/germany.png`} style={{ width: "15px" }} />
                </button>
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    title="Felemenkçe Güncelleme"
                    onClick={() => { setIsUpdate(row); setLang("dut"); }}
                >
                    <img src={process.env.PUBLIC_URL + `/flags/netherlands.png`} style={{ width: "15px" }} />
                </button>
                <button
                    type="button"
                    className="btn btn-outline-secondary deleterow"
                    title="Sil"
                    onClick={() => setIsDelete(row)}
                >
                    <i className="icofont-ui-delete text-danger"></i>
                </button>
            </div>
        }
    ]

    return (
        <div className="container-xxl">
            <PageHeader headerTitle="Reis Verim" renderRight={() => {
                return <div className="col-auto d-flex w-sm-100">
                    <button className="btn btn-dark btn-set-task w-sm-100" onClick={() => { setIsModal(true) }}>
                        <i className="icofont-plus-circle me-2 fs-6"></i>
                        Yeni Ürün
                    </button>
                </div>
            }} />
            <div className="row clearfix g-3">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            {yield1 &&
                                <DataTable
                                    title="Verim"
                                    columns={columnT}
                                    data={[...yield1].reverse()}
                                    defaultSortField="title"
                                    pagination
                                    selectableRows={false}
                                    className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
                                    highlightOnHover={true}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={isModal}
                onHide={() => {
                    setIsModal(null);
                    setItem({
                        firstPhoto: null,
                        secondPhoto: null,
                        thirdPhoto: null,
                        alt: null,
                        name1: null,
                        isNative: "false",
                        text1: null,
                        type1: null,
                        origin: null,
                        size1: null,
                        packaging: null,
                        desc1: null,
                        calories: null,
                        totalFat: null,
                        saturatedFat: null,
                        transFat: null,
                        cholesterol: null,
                        sodium: null,
                        totalCarbohydrate: null,
                        dietaryFiber: null,
                        totalSugars: null,
                        protein: null,
                        nameEng: null,
                        textEng: null,
                        typeEng: null,
                        originEng: null,
                        sizeEng: null,
                        packagingEng: null,
                        descEng: null,
                        nameFra: null,
                        textFra: null,
                        typeFra: null,
                        originFra: null,
                        sizeFra: null,
                        packagingFra: null,
                        descFra: null,
                        proteinFra: null,
                        nameGer: null,
                        textGer: null,
                        typeGer: null,
                        originGer: null,
                        sizeGer: null,
                        packagingGer: null,
                        descGer: null,
                        nameDut: null,
                        textDut: null,
                        typeDut: null,
                        originDut: null,
                        sizeDut: null,
                        packagingDut: null,
                        descDut: null
                    });
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
                dialogClassName="modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Yeni Ürün</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row g-3 mb-3">
                        <div className="col-sm-8">
                            <label htmlFor="name1" className="form-label">Ürün Adı</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name1"
                                value={item && item.name1}
                                onChange={handleOnChange}
                                style={{ borderColor: `${error.field === "name1" ? "red" : "var(--border-color)"}` }}
                            />
                            {error.field === "name1" &&
                                <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                            }
                        </div>
                        <div className="col-sm-4">
                            <label htmlFor="isNative" className="form-label">Ürün Yerli mi?</label>
                            <select
                                id="isNative"
                                className="form-select"
                                onChange={handleOnChange}
                                style={{ borderColor: `${error.field === "isNative" ? "red" : "var(--border-color)"}` }}
                            >
                                {
                                    item.isNative === "true" &&
                                    <>
                                        <option value={"true"} selected>Evet</option>
                                        <option value={"false"}>Hayır</option>
                                    </>
                                }
                                {
                                    item.isNative === "false" &&
                                    <>
                                        <option value={"true"}>Evet</option>
                                        <option value={"false"} selected>Hayır</option>
                                    </>
                                }
                            </select>
                            {error.field === "isNative" &&
                                <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                            }
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="text1" className="form-label">
                            {"Yazı"}
                        </label>
                        <textarea
                            className="form-control"
                            id="text1"
                            rows="5"
                            value={item && item.text1}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "text1" ? "red" : "var(--border-color)"}` }}
                        ></textarea>
                        {error.field === "text1" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                    <div className="mb-3">
                        <div className="row">
                            <div className="col-6">
                                <label htmlFor="type1" className="form-label">Çeşit</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="type1"
                                    value={item && item.type1}
                                    onChange={handleOnChange}
                                    style={{ borderColor: `${error.field === "type1" ? "red" : "var(--border-color)"}` }}
                                />
                                {error.field === "type1" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="col-6">
                                <label htmlFor="origin" className="form-label">Menşei</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="origin"
                                    value={item && item.origin}
                                    onChange={handleOnChange}
                                    style={{ borderColor: `${error.field === "origin" ? "red" : "var(--border-color)"}` }}
                                />
                                {error.field === "origin" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="row">
                            <div className="col-6">
                                <label htmlFor="size1" className="form-label">Boyut</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="size1"
                                    value={item && item.size1}
                                    onChange={handleOnChange}
                                    style={{ borderColor: `${error.field === "size1" ? "red" : "var(--border-color)"}` }}
                                />
                                {error.field === "size1" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="col-6">
                                <label htmlFor="packaging" className="form-label">Ambalaj</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="packaging"
                                    value={item && item.packaging}
                                    onChange={handleOnChange}
                                    style={{ borderColor: `${error.field === "packaging" ? "red" : "var(--border-color)"}` }}
                                />
                                {error.field === "packaging" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="desc1" className="form-label">
                            {"Açıklama"}
                        </label>
                        {item &&
                            <ReactQuill
                                theme="snow"
                                id="desc1"
                                value={item.desc1}
                                onChange={handleTextInput}
                                style={{ borderColor: `${error.field === "desc1" ? "red" : "var(--border-color)"}` }}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ 'size': [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                        { 'indent': '-1' }, { 'indent': '+1' }],
                                        ['link'],
                                        ['clean'],
                                        [{ 'color': [] }, { 'background': [] }]
                                    ]
                                }}
                            />}
                        {error.field === "desc1" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                    <div className="mb-3">
                        <div className="row">
                            <div className="col-6">
                                <label htmlFor="calories" className="form-label">Kalori</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="calories"
                                    value={item && item.calories}
                                    onChange={handleOnChange}
                                    style={{ borderColor: `${error.field === "calories" ? "red" : "var(--border-color)"}` }}
                                />
                                {error.field === "calories" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="col-6">
                                <label htmlFor="totalFat" className="form-label">Toplam Yağ</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="totalFat"
                                    value={item && item.totalFat}
                                    onChange={handleOnChange}
                                    style={{ borderColor: `${error.field === "totalFat" ? "red" : "var(--border-color)"}` }}
                                />
                                {error.field === "totalFat" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="row">
                            <div className="col-6">
                                <label htmlFor="saturatedFat" className="form-label">Doymuş Yağ</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="saturatedFat"
                                    value={item && item.saturatedFat}
                                    onChange={handleOnChange}
                                    style={{ borderColor: `${error.field === "saturatedFat" ? "red" : "var(--border-color)"}` }}
                                />
                                {error.field === "saturatedFat" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="col-6">
                                <label htmlFor="transFat" className="form-label">Trans Yağ</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="transFat"
                                    value={item && item.transFat}
                                    onChange={handleOnChange}
                                    style={{ borderColor: `${error.field === "transFat" ? "red" : "var(--border-color)"}` }}
                                />
                                {error.field === "transFat" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="row">
                            <div className="col-6">
                                <label htmlFor="cholesterol" className="form-label">Kolestrol</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="cholesterol"
                                    value={item && item.cholesterol}
                                    onChange={handleOnChange}
                                    style={{ borderColor: `${error.field === "cholesterol" ? "red" : "var(--border-color)"}` }}
                                />
                                {error.field === "cholesterol" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="col-6">
                                <label htmlFor="sodium" className="form-label">Sodyum</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="sodium"
                                    value={item && item.sodium}
                                    onChange={handleOnChange}
                                    style={{ borderColor: `${error.field === "sodium" ? "red" : "var(--border-color)"}` }}
                                />
                                {error.field === "sodium" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="row">
                            <div className="col-6">
                                <label htmlFor="totalCarbohydrate" className="form-label">Toplam Karbonhidrat</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="totalCarbohydrate"
                                    value={item && item.totalCarbohydrate}
                                    onChange={handleOnChange}
                                    style={{ borderColor: `${error.field === "totalCarbohydrate" ? "red" : "var(--border-color)"}` }}
                                />
                                {error.field === "totalCarbohydrate" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="col-6">
                                <label htmlFor="dietaryFiber" className="form-label">Diyetetik Lif</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="dietaryFiber"
                                    value={item && item.dietaryFiber}
                                    onChange={handleOnChange}
                                    style={{ borderColor: `${error.field === "dietaryFiber" ? "red" : "var(--border-color)"}` }}
                                />
                                {error.field === "dietaryFiber" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="row">
                            <div className="col-6">
                                <label htmlFor="totalSugars" className="form-label">Toplam Şeker</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="totalSugars"
                                    value={item && item.totalSugars}
                                    onChange={handleOnChange}
                                    style={{ borderColor: `${error.field === "totalSugars" ? "red" : "var(--border-color)"}` }}
                                />
                                {error.field === "totalSugars" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="col-6">
                                <label htmlFor="protein" className="form-label">Protein</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="protein"
                                    value={item && item.protein}
                                    onChange={handleOnChange}
                                    style={{ borderColor: `${error.field === "protein" ? "red" : "var(--border-color)"}` }}
                                />
                                {error.field === "protein" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="alt" className="form-label">
                            {"Seo Kelimeler "}
                            <span className="text-danger">
                                (Lütfen 5 Kelimeden Fazla Yazmayınız!)
                            </span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="alt"
                            value={item && item.alt}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "alt" ? "red" : "var(--border-color)"}` }}
                        />
                        {error.field === "alt" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="firstPhoto" className="form-label">
                            {"1.Fotoğraf Yükle"}
                        </label>
                        {error.field === "firstPhoto" &&
                            <label className="form-label" style={{ color: "red", marginLeft: "5px" }}>{error.note}</label>
                        }
                        <input
                            className="form-control"
                            type="file"
                            id="firstPhoto"
                            onChange={handlePhotoInput}
                            style={{ borderColor: `${error.field === "firstPhoto" ? "red" : "var(--border-color)"}` }}
                        />
                        <div className="form-label" style={{ color: "red" }}>
                            {"Bu alanın geçerli görsel öğe boyutu: 1890x1350"}
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="secondPhoto" className="form-label">
                            {"2.Fotoğraf Yükle"}
                        </label>
                        {error.field === "secondPhoto" &&
                            <label className="form-label" style={{ color: "red", marginLeft: "5px" }}>{error.note}</label>
                        }
                        <input
                            className="form-control"
                            type="file"
                            id="secondPhoto"
                            onChange={handlePhotoInput}
                            style={{ borderColor: `${error.field === "secondPhoto" ? "red" : "var(--border-color)"}` }}
                        />
                        <div className="form-label" style={{ color: "red" }}>
                            {"Bu alanın geçerli görsel öğe boyutu: 1890x1350"}
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="thirdPhoto" className="form-label">
                            {"3.Fotoğraf Yükle"}
                        </label>
                        {error.field === "thirdPhoto" &&
                            <label className="form-label" style={{ color: "red", marginLeft: "5px" }}>{error.note}</label>
                        }
                        <input
                            className="form-control"
                            type="file"
                            id="thirdPhoto"
                            onChange={handlePhotoInput}
                            style={{ borderColor: `${error.field === "thirdPhoto" ? "red" : "var(--border-color)"}` }}
                        />
                        <div className="form-label" style={{ color: "red" }}>
                            {"Bu alanın geçerli görsel öğe boyutu: 1890x1350"}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleSubmit()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "tur"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
                dialogClassName="modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Ürünü Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isUpdate &&
                        <>
                            <div className="row g-3 mb-3">
                                <div className="col-sm-8">
                                    <label htmlFor="name1" className="form-label">Ürün Adı</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name1"
                                        value={isUpdate && isUpdate.name1}
                                        onChange={handleOnUpdateChange}
                                        style={{ borderColor: `${error.field === "name1" ? "red" : "var(--border-color)"}` }}
                                    />
                                    {error.field === "name1" &&
                                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                    }
                                </div>
                                <div className="col-sm-4">
                                    <label htmlFor="isNative" className="form-label">Ürün Yerli mi?</label>
                                    <select
                                        id="isNative"
                                        className="form-select"
                                        onChange={handleOnUpdateChange}
                                        style={{ borderColor: `${error.field === "isNative" ? "red" : "var(--border-color)"}` }}
                                    >
                                        {
                                            isUpdate.isNative === "true" &&
                                            <>
                                                <option value={"true"} selected>Evet</option>
                                                <option value={"false"}>Hayır</option>
                                            </>
                                        }
                                        {
                                            isUpdate.isNative === "false" &&
                                            <>
                                                <option value={"true"}>Evet</option>
                                                <option value={"false"} selected>Hayır</option>
                                            </>
                                        }
                                    </select>
                                    {error.field === "isNative" &&
                                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                    }
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="text1" className="form-label">
                                    {"Yazı"}
                                </label>
                                <textarea
                                    className="form-control"
                                    id="text1"
                                    rows="5"
                                    value={isUpdate && isUpdate.text1}
                                    onChange={handleOnUpdateChange}
                                    style={{ borderColor: `${error.field === "text1" ? "red" : "var(--border-color)"}` }}
                                ></textarea>
                                {error.field === "text1" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="mb-3">
                                <div className="row">
                                    <div className="col-6">
                                        <label htmlFor="type1" className="form-label">Çeşit</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="type1"
                                            value={isUpdate && isUpdate.type1}
                                            onChange={handleOnUpdateChange}
                                            style={{ borderColor: `${error.field === "type1" ? "red" : "var(--border-color)"}` }}
                                        />
                                        {error.field === "type1" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="origin" className="form-label">Menşei</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="origin"
                                            value={isUpdate && isUpdate.origin}
                                            onChange={handleOnUpdateChange}
                                            style={{ borderColor: `${error.field === "origin" ? "red" : "var(--border-color)"}` }}
                                        />
                                        {error.field === "origin" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="row">
                                    <div className="col-6">
                                        <label htmlFor="size1" className="form-label">Boyut</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="size1"
                                            value={isUpdate && isUpdate.size1}
                                            onChange={handleOnUpdateChange}
                                            style={{ borderColor: `${error.field === "size1" ? "red" : "var(--border-color)"}` }}
                                        />
                                        {error.field === "size1" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="packaging" className="form-label">Ambalaj</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="packaging"
                                            value={isUpdate && isUpdate.packaging}
                                            onChange={handleOnUpdateChange}
                                            style={{ borderColor: `${error.field === "packaging" ? "red" : "var(--border-color)"}` }}
                                        />
                                        {error.field === "packaging" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="desc1" className="form-label">
                                    {"Açıklama"}
                                </label>
                                {isUpdate &&
                                    <ReactQuill
                                        theme="snow"
                                        id="desc1"
                                        value={isUpdate.desc1}
                                        onChange={handleTextInputUpdate}
                                        style={{ borderColor: `${error.field === "desc1" ? "red" : "var(--border-color)"}` }}
                                        modules={{
                                            toolbar: [
                                                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                                [{ 'size': [] }],
                                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                                { 'indent': '-1' }, { 'indent': '+1' }],
                                                ['link'],
                                                ['clean'],
                                                [{ 'color': [] }, { 'background': [] }]
                                            ]
                                        }}
                                    />}
                                {error.field === "desc1" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="mb-3">
                                <div className="row">
                                    <div className="col-6">
                                        <label htmlFor="calories" className="form-label">Kalori</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="calories"
                                            value={isUpdate && isUpdate.calories}
                                            onChange={handleOnUpdateChange}
                                            style={{ borderColor: `${error.field === "calories" ? "red" : "var(--border-color)"}` }}
                                        />
                                        {error.field === "calories" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="totalFat" className="form-label">Toplam Yağ</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="totalFat"
                                            value={isUpdate && isUpdate.totalFat}
                                            onChange={handleOnUpdateChange}
                                            style={{ borderColor: `${error.field === "totalFat" ? "red" : "var(--border-color)"}` }}
                                        />
                                        {error.field === "totalFat" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="row">
                                    <div className="col-6">
                                        <label htmlFor="saturatedFat" className="form-label">Doymuş Yağ</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="saturatedFat"
                                            value={isUpdate && isUpdate.saturatedFat}
                                            onChange={handleOnUpdateChange}
                                            style={{ borderColor: `${error.field === "saturatedFat" ? "red" : "var(--border-color)"}` }}
                                        />
                                        {error.field === "saturatedFat" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="transFat" className="form-label">Trans Yağ</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="transFat"
                                            value={isUpdate && isUpdate.transFat}
                                            onChange={handleOnUpdateChange}
                                            style={{ borderColor: `${error.field === "transFat" ? "red" : "var(--border-color)"}` }}
                                        />
                                        {error.field === "transFat" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="row">
                                    <div className="col-6">
                                        <label htmlFor="cholesterol" className="form-label">Kolestrol</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="cholesterol"
                                            value={isUpdate && isUpdate.cholesterol}
                                            onChange={handleOnUpdateChange}
                                            style={{ borderColor: `${error.field === "cholesterol" ? "red" : "var(--border-color)"}` }}
                                        />
                                        {error.field === "cholesterol" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="sodium" className="form-label">Sodyum</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="sodium"
                                            value={isUpdate && isUpdate.sodium}
                                            onChange={handleOnUpdateChange}
                                            style={{ borderColor: `${error.field === "sodium" ? "red" : "var(--border-color)"}` }}
                                        />
                                        {error.field === "sodium" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="row">
                                    <div className="col-6">
                                        <label htmlFor="totalCarbohydrate" className="form-label">Toplam Karbonhidrat</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="totalCarbohydrate"
                                            value={isUpdate && isUpdate.totalCarbohydrate}
                                            onChange={handleOnUpdateChange}
                                            style={{ borderColor: `${error.field === "totalCarbohydrate" ? "red" : "var(--border-color)"}` }}
                                        />
                                        {error.field === "totalCarbohydrate" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="dietaryFiber" className="form-label">Diyetetik Lif</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="dietaryFiber"
                                            value={isUpdate && isUpdate.dietaryFiber}
                                            onChange={handleOnUpdateChange}
                                            style={{ borderColor: `${error.field === "dietaryFiber" ? "red" : "var(--border-color)"}` }}
                                        />
                                        {error.field === "dietaryFiber" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="row">
                                    <div className="col-6">
                                        <label htmlFor="totalSugars" className="form-label">Toplam Şeker</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="totalSugars"
                                            value={isUpdate && isUpdate.totalSugars}
                                            onChange={handleOnUpdateChange}
                                            style={{ borderColor: `${error.field === "totalSugars" ? "red" : "var(--border-color)"}` }}
                                        />
                                        {error.field === "totalSugars" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="protein" className="form-label">Protein</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="protein"
                                            value={isUpdate && isUpdate.protein}
                                            onChange={handleOnUpdateChange}
                                            style={{ borderColor: `${error.field === "protein" ? "red" : "var(--border-color)"}` }}
                                        />
                                        {error.field === "protein" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="alt" className="form-label">
                                    {"Seo Kelimeler "}
                                    <span className="text-danger">
                                        (Lütfen 5 Kelimeden Fazla Yazmayınız!)
                                    </span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="alt"
                                    value={isUpdate && isUpdate.alt}
                                    onChange={handleOnUpdateChange}
                                    style={{ borderColor: `${error.field === "alt" ? "red" : "var(--border-color)"}` }}
                                />
                                {error.field === "alt" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="mb-3">
                                <label htmlFor="firstPhoto" className="form-label">
                                    {"1.Fotoğraf Yükle"}
                                </label>
                                <input
                                    className="form-control"
                                    type="file"
                                    id="firstPhoto"
                                    onChange={handlePhotoUpdate}
                                />
                                <div className="form-label" style={{ color: "red" }}>
                                    {"Bu alanın geçerli görsel öğe boyutu: 1890x1350"}
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="secondPhoto" className="form-label">
                                    {"2.Fotoğraf Yükle"}
                                </label>
                                <input
                                    className="form-control"
                                    type="file"
                                    id="secondPhoto"
                                    onChange={handlePhotoUpdate}
                                />
                                <div className="form-label" style={{ color: "red" }}>
                                    {"Bu alanın geçerli görsel öğe boyutu: 1890x1350"}
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="thirdPhoto" className="form-label">
                                    {"3.Fotoğraf Yükle"}
                                </label>
                                <input
                                    className="form-control"
                                    type="file"
                                    id="thirdPhoto"
                                    onChange={handlePhotoUpdate}
                                />
                                <div className="form-label" style={{ color: "red" }}>
                                    {"Bu alanın geçerli görsel öğe boyutu: 1890x1350"}
                                </div>
                            </div>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "eng"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
                dialogClassName="modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">İngilizce Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isUpdate &&
                        <>
                            <div className="g-3 mb-3">
                                <label htmlFor="nameEng" className="form-label">Ürün Adı</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="nameEng"
                                    value={isUpdate && isUpdate.nameEng}
                                    onChange={handleOnUpdateChange}
                                    style={{ borderColor: `${error.field === "nameEng" ? "red" : "var(--border-color)"}` }}
                                />
                                {error.field === "nameEng" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="mb-3">
                                <label htmlFor="textEng" className="form-label">
                                    {"Yazı"}
                                </label>
                                <textarea
                                    className="form-control"
                                    id="textEng"
                                    rows="5"
                                    value={isUpdate && isUpdate.textEng}
                                    onChange={handleOnUpdateChange}
                                    style={{ borderColor: `${error.field === "textEng" ? "red" : "var(--border-color)"}` }}
                                ></textarea>
                                {error.field === "textEng" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="mb-3">
                                <div className="row">
                                    <div className="col-6">
                                        <label htmlFor="typeEng" className="form-label">Çeşit</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="typeEng"
                                            value={isUpdate && isUpdate.typeEng}
                                            onChange={handleOnUpdateChange}
                                            style={{ borderColor: `${error.field === "typeEng" ? "red" : "var(--border-color)"}` }}
                                        />
                                        {error.field === "typeEng" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="originEng" className="form-label">Menşei</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="originEng"
                                            value={isUpdate && isUpdate.originEng}
                                            onChange={handleOnUpdateChange}
                                            style={{ borderColor: `${error.field === "originEng" ? "red" : "var(--border-color)"}` }}
                                        />
                                        {error.field === "originEng" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="row">
                                    <div className="col-6">
                                        <label htmlFor="sizeEng" className="form-label">Boyut</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="sizeEng"
                                            value={isUpdate && isUpdate.sizeEng}
                                            onChange={handleOnUpdateChange}
                                            style={{ borderColor: `${error.field === "sizeEng" ? "red" : "var(--border-color)"}` }}
                                        />
                                        {error.field === "sizeEng" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="packagingEng" className="form-label">Ambalaj</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="packagingEng"
                                            value={isUpdate && isUpdate.packagingEng}
                                            onChange={handleOnUpdateChange}
                                            style={{ borderColor: `${error.field === "packagingEng" ? "red" : "var(--border-color)"}` }}
                                        />
                                        {error.field === "packagingEng" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="descEng" className="form-label">
                                    {"Açıklama"}
                                </label>
                                {isUpdate &&
                                    <ReactQuill
                                        theme="snow"
                                        id="descEng"
                                        value={isUpdate.descEng}
                                        onChange={handleTextInputUpdate}
                                        style={{ borderColor: `${error.field === "descEng" ? "red" : "var(--border-color)"}` }}
                                        modules={{
                                            toolbar: [
                                                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                                [{ 'size': [] }],
                                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                                { 'indent': '-1' }, { 'indent': '+1' }],
                                                ['link'],
                                                ['clean'],
                                                [{ 'color': [] }, { 'background': [] }]
                                            ]
                                        }}
                                    />}
                                {error.field === "descEng" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "fra"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
                dialogClassName="modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Fransızca Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isUpdate &&
                        <>
                            <div className="g-3 mb-3">
                                <label htmlFor="nameFra" className="form-label">Ürün Adı</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="nameFra"
                                    value={isUpdate && isUpdate.nameFra}
                                    onChange={handleOnUpdateChange}
                                    style={{ borderColor: `${error.field === "nameFra" ? "red" : "var(--border-color)"}` }}
                                />
                                {error.field === "nameFra" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="mb-3">
                                <label htmlFor="textFra" className="form-label">
                                    {"Yazı"}
                                </label>
                                <textarea
                                    className="form-control"
                                    id="textFra"
                                    rows="5"
                                    value={isUpdate && isUpdate.textFra}
                                    onChange={handleOnUpdateChange}
                                    style={{ borderColor: `${error.field === "textFra" ? "red" : "var(--border-color)"}` }}
                                ></textarea>
                                {error.field === "textFra" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="mb-3">
                                <div className="row">
                                    <div className="col-6">
                                        <label htmlFor="typeFra" className="form-label">Çeşit</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="typeFra"
                                            value={isUpdate && isUpdate.typeFra}
                                            onChange={handleOnUpdateChange}
                                            style={{ borderColor: `${error.field === "typeFra" ? "red" : "var(--border-color)"}` }}
                                        />
                                        {error.field === "typeFra" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="originFra" className="form-label">Menşei</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="originFra"
                                            value={isUpdate && isUpdate.originFra}
                                            onChange={handleOnUpdateChange}
                                            style={{ borderColor: `${error.field === "originFra" ? "red" : "var(--border-color)"}` }}
                                        />
                                        {error.field === "originFra" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="row">
                                    <div className="col-6">
                                        <label htmlFor="sizeFra" className="form-label">Boyut</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="sizeFra"
                                            value={isUpdate && isUpdate.sizeFra}
                                            onChange={handleOnUpdateChange}
                                            style={{ borderColor: `${error.field === "sizeFra" ? "red" : "var(--border-color)"}` }}
                                        />
                                        {error.field === "sizeFra" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="packagingFra" className="form-label">Ambalaj</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="packagingFra"
                                            value={isUpdate && isUpdate.packagingFra}
                                            onChange={handleOnUpdateChange}
                                            style={{ borderColor: `${error.field === "packagingFra" ? "red" : "var(--border-color)"}` }}
                                        />
                                        {error.field === "packagingFra" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="descFra" className="form-label">
                                    {"Açıklama"}
                                </label>
                                {isUpdate &&
                                    <ReactQuill
                                        theme="snow"
                                        id="descFra"
                                        value={isUpdate.descFra}
                                        onChange={handleTextInputUpdate}
                                        style={{ borderColor: `${error.field === "descFra" ? "red" : "var(--border-color)"}` }}
                                        modules={{
                                            toolbar: [
                                                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                                [{ 'size': [] }],
                                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                                { 'indent': '-1' }, { 'indent': '+1' }],
                                                ['link'],
                                                ['clean'],
                                                [{ 'color': [] }, { 'background': [] }]
                                            ]
                                        }}
                                    />}
                                {error.field === "descFra" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "ger"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
                dialogClassName="modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Almanca Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isUpdate &&
                        <>
                            <div className="g-3 mb-3">
                                <label htmlFor="nameGer" className="form-label">Ürün Adı</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="nameGer"
                                    value={isUpdate && isUpdate.nameGer}
                                    onChange={handleOnUpdateChange}
                                    style={{ borderColor: `${error.field === "nameGer" ? "red" : "var(--border-color)"}` }}
                                />
                                {error.field === "nameGer" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="mb-3">
                                <label htmlFor="textGer" className="form-label">
                                    {"Yazı"}
                                </label>
                                <textarea
                                    className="form-control"
                                    id="textGer"
                                    rows="5"
                                    value={isUpdate && isUpdate.textGer}
                                    onChange={handleOnUpdateChange}
                                    style={{ borderColor: `${error.field === "textGer" ? "red" : "var(--border-color)"}` }}
                                ></textarea>
                                {error.field === "textGer" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="mb-3">
                                <div className="row">
                                    <div className="col-6">
                                        <label htmlFor="typeGer" className="form-label">Çeşit</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="typeGer"
                                            value={isUpdate && isUpdate.typeGer}
                                            onChange={handleOnUpdateChange}
                                            style={{ borderColor: `${error.field === "typeGer" ? "red" : "var(--border-color)"}` }}
                                        />
                                        {error.field === "typeGer" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="originGer" className="form-label">Menşei</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="originGer"
                                            value={isUpdate && isUpdate.originGer}
                                            onChange={handleOnUpdateChange}
                                            style={{ borderColor: `${error.field === "originGer" ? "red" : "var(--border-color)"}` }}
                                        />
                                        {error.field === "originGer" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="row">
                                    <div className="col-6">
                                        <label htmlFor="sizeGer" className="form-label">Boyut</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="sizeGer"
                                            value={isUpdate && isUpdate.sizeGer}
                                            onChange={handleOnUpdateChange}
                                            style={{ borderColor: `${error.field === "sizeGer" ? "red" : "var(--border-color)"}` }}
                                        />
                                        {error.field === "sizeGer" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="packagingGer" className="form-label">Ambalaj</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="packagingGer"
                                            value={isUpdate && isUpdate.packagingGer}
                                            onChange={handleOnUpdateChange}
                                            style={{ borderColor: `${error.field === "packagingGer" ? "red" : "var(--border-color)"}` }}
                                        />
                                        {error.field === "packagingGer" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="descGer" className="form-label">
                                    {"Açıklama"}
                                </label>
                                {isUpdate &&
                                    <ReactQuill
                                        theme="snow"
                                        id="descGer"
                                        value={isUpdate.descGer}
                                        onChange={handleTextInputUpdate}
                                        style={{ borderColor: `${error.field === "descGer" ? "red" : "var(--border-color)"}` }}
                                        modules={{
                                            toolbar: [
                                                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                                [{ 'size': [] }],
                                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                                { 'indent': '-1' }, { 'indent': '+1' }],
                                                ['link'],
                                                ['clean'],
                                                [{ 'color': [] }, { 'background': [] }]
                                            ]
                                        }}
                                    />}
                                {error.field === "descGer" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "dut"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
                dialogClassName="modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Felemenkçe Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isUpdate &&
                        <>
                            <div className="g-3 mb-3">
                                <label htmlFor="nameDut" className="form-label">Ürün Adı</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="nameDut"
                                    value={isUpdate && isUpdate.nameDut}
                                    onChange={handleOnUpdateChange}
                                    style={{ borderColor: `${error.field === "nameDut" ? "red" : "var(--border-color)"}` }}
                                />
                                {error.field === "nameDut" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="mb-3">
                                <label htmlFor="textDut" className="form-label">
                                    {"Yazı"}
                                </label>
                                <textarea
                                    className="form-control"
                                    id="textDut"
                                    rows="5"
                                    value={isUpdate && isUpdate.textDut}
                                    onChange={handleOnUpdateChange}
                                    style={{ borderColor: `${error.field === "textDut" ? "red" : "var(--border-color)"}` }}
                                ></textarea>
                                {error.field === "textDut" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="mb-3">
                                <div className="row">
                                    <div className="col-6">
                                        <label htmlFor="typeDut" className="form-label">Çeşit</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="typeDut"
                                            value={isUpdate && isUpdate.typeDut}
                                            onChange={handleOnUpdateChange}
                                            style={{ borderColor: `${error.field === "typeDut" ? "red" : "var(--border-color)"}` }}
                                        />
                                        {error.field === "typeDut" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="originDut" className="form-label">Menşei</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="originDut"
                                            value={isUpdate && isUpdate.originDut}
                                            onChange={handleOnUpdateChange}
                                            style={{ borderColor: `${error.field === "originDut" ? "red" : "var(--border-color)"}` }}
                                        />
                                        {error.field === "originDut" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="row">
                                    <div className="col-6">
                                        <label htmlFor="sizeDut" className="form-label">Boyut</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="sizeDut"
                                            value={isUpdate && isUpdate.sizeDut}
                                            onChange={handleOnUpdateChange}
                                            style={{ borderColor: `${error.field === "sizeDut" ? "red" : "var(--border-color)"}` }}
                                        />
                                        {error.field === "sizeDut" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="packagingDut" className="form-label">Ambalaj</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="packagingDut"
                                            value={isUpdate && isUpdate.packagingDut}
                                            onChange={handleOnUpdateChange}
                                            style={{ borderColor: `${error.field === "packagingDut" ? "red" : "var(--border-color)"}` }}
                                        />
                                        {error.field === "packagingDut" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="descDut" className="form-label">
                                    {"Açıklama"}
                                </label>
                                {isUpdate &&
                                    <ReactQuill
                                        theme="snow"
                                        id="descDut"
                                        value={isUpdate.descDut}
                                        onChange={handleTextInputUpdate}
                                        style={{ borderColor: `${error.field === "descDut" ? "red" : "var(--border-color)"}` }}
                                        modules={{
                                            toolbar: [
                                                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                                [{ 'size': [] }],
                                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                                { 'indent': '-1' }, { 'indent': '+1' }],
                                                ['link'],
                                                ['clean'],
                                                [{ 'color': [] }, { 'background': [] }]
                                            ]
                                        }}
                                    />}
                                {error.field === "descDut" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                centered
                show={isDelete}
                onHide={() => {
                    setIsDelete(null);
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Uyarı</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <div className="form-label">
                            Bu ürünü silmek istediğinize emin misiniz?
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="danger"
                        className="me-1 text-white"
                        onClick={() => handleDelete(isDelete.id)}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Siliniyor...
                                </> :
                                <>
                                    <i className="icofont-ui-delete"></i>
                                    &ensp;
                                    Sil
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Yield;