import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import PageHeader from "../../components/common/PageHeader";
import Timeline from "./Timeline";
import { useXhr } from "hooks/useXhr";
import { useTimelineListContext } from "hooks/useTimelineListContext";
import { Button, Spinner } from "react-bootstrap";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useHistoryContext } from "hooks/useHistoryContext";

function TimelineList() {
    //States
    const [item, setItem] = useState({
        photo: null,
        alt: null,
        date1: null,
        text1: null,
        textEng: null,
        textFra: null,
        textGer: null,
        textDut: null
    });
    const [status, setStatus] = useState(false);
    const [isUpdate, setIsUpdate] = useState(null);
    const [isHistory, setIsHistory] = useState(null);
    const [lang, setLang] = useState(null);
    const [langHistory, setLangHistory] = useState(null);
    const [inputLength, setInputLength] = useState(90);
    const [isDelete, setIsDelete] = useState(null);
    const [isModal, setIsModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState({
        field: null,
        note: null
    });

    //Hooks
    const { customXhr } = useXhr()
    const { timelineList, dispatch } = useTimelineListContext();
    const { history, dispatch: historyDispatch } = useHistoryContext();

    const requiredFields = ['alt', 'date1', 'text1'];
    const requiredFieldsForHistory = ['text1'];

    const handleOnChange = (e) => {
        const { id, value } = e.target;

        if (id === "text1") {
            if (90 - value.length >= 0) {
                setInputLength(90 - value.length);
                setItem(prevState => ({
                    ...prevState,
                    [id]: value
                }));
            }
        } else {
            setItem(prevState => ({
                ...prevState,
                [id]: value
            }));
        }
    };

    const handleOnUpdateChange = (e) => {
        const { id, value } = e.target;

        if (id === "text1") {
            if (90 - value.length >= 0) {
                setInputLength(90 - value.length);
                setIsUpdate(prevState => ({
                    ...prevState,
                    [id]: value
                }));
            }
        } else {
            setIsUpdate(prevState => ({
                ...prevState,
                [id]: value
            }));
        }
    };

    const handleOnHistoryChange = (e) => {
        if (langHistory === "tur") {
            setIsHistory(prevState => ({
                ...prevState,
                text1: e
            }));
        } else if (langHistory === "eng") {
            setIsHistory(prevState => ({
                ...prevState,
                textEng: e
            }));
        } else if (langHistory === "fra") {
            setIsHistory(prevState => ({
                ...prevState,
                textFra: e
            }));
        } else if (langHistory === "ger") {
            setIsHistory(prevState => ({
                ...prevState,
                textGer: e
            }));
        } else if (langHistory === "dut") {
            setIsHistory(prevState => ({
                ...prevState,
                textDut: e
            }));
        }
    };

    const generateUniqueFileName = (file) => {
        const timestamp = Date.now();
        const randomNumber = Math.floor(Math.random() * 10000);
        const extension = file.name.split('.').pop();

        return `${timestamp}_${randomNumber}.${extension}`;
    };

    const handlePhotoInput = (e) => {
        const file = e.target.files[0];
        const id = e.target.id;

        const newFileName = generateUniqueFileName(file);

        const newFile = new File([file], newFileName, { type: file.type });

        setItem(prevState => ({
            ...prevState,
            [id]: newFile
        }));
    };

    const handlePhotoUpdate = (e) => {
        const file = e.target.files[0];
        const id = e.target.id;

        const newFileName = generateUniqueFileName(file);

        const newFile = new File([file], newFileName, { type: file.type });

        setIsUpdate(prevState => ({
            ...prevState,
            [id]: newFile
        }));
    };

    const handleDelete = async (id) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('module', 'timeline_list');
        formData.append('action', 'delete');
        formData.append('id', id);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setStatus(!status);
            setIsDelete(null);
            setIsLoading(false);
        };
    };

    const handleUpdate = async () => {
        for (const field of requiredFields) {
            if (!isUpdate[field] || isUpdate[field] === "") {
                return (
                    setError({
                        field: field,
                        note: `Zorunlu alan`
                    }),
                    setIsLoading(false)
                )
            }
        }
        setIsLoading(true);
        const formData = new FormData();
        formData.append('module', 'timeline_list');
        formData.append('action', 'update');
        formData.append('id', isUpdate.id);
        formData.append('photo', isUpdate.photo);
        formData.append('alt', isUpdate.alt);
        formData.append('date1', isUpdate.date1);
        formData.append('text1', isUpdate.text1);
        formData.append('textEng', isUpdate.textEng);
        formData.append('textFra', isUpdate.textFra);
        formData.append('textGer', isUpdate.textGer);
        formData.append('textDut', isUpdate.textDut);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setIsUpdate(null);
            setLang(null);
            setStatus(!status);
            setError({
                field: null,
                note: null
            });
            setIsLoading(false);
        };
    };

    const handleHistory = async () => {
        for (const field of requiredFieldsForHistory) {
            if (!isHistory[field] || isHistory[field] === "") {
                return (
                    setError({
                        field: field,
                        note: `Zorunlu alan`
                    }),
                    setIsLoading(false)
                )
            }
        }
        setIsLoading(true);
        const formData = new FormData();
        formData.append('module', 'history');
        formData.append('action', 'update');
        formData.append('id', isHistory.id);
        formData.append('text1', isHistory.text1);
        formData.append('textEng', isHistory.textEng);
        formData.append('textFra', isHistory.textFra);
        formData.append('textGer', isHistory.textGer);
        formData.append('textDut', isHistory.textDut);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setIsHistory(null);
            setLangHistory(null);
            setStatus(!status);
            setError({
                field: null,
                note: null
            });
            setIsLoading(false);
        };
    };

    const handleSubmit = async () => {
        for (const field of requiredFields) {
            if (!item[field] || item[field] === "") {
                return (
                    setError({
                        field: field,
                        note: `Zorunlu alan`
                    }),
                    setIsLoading(false)
                )
            }
        }
        setIsLoading(true);
        const formData = new FormData();
        formData.append('module', 'timeline_list');
        formData.append('action', 'insert');
        formData.append('photo', item.photo);
        formData.append('alt', item.alt);
        formData.append('date1', item.date1);
        formData.append('text1', item.text1);
        formData.append('textEng', item.textEng);
        formData.append('textFra', item.textFra);
        formData.append('textGer', item.textGer);
        formData.append('textDut', item.textDut);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setItem(null);
            setIsModal(null);
            setStatus(!status);
            setError({
                field: null,
                note: null
            });
            setIsLoading(false);
        };
    };

    const getTimelineList = async () => {
        const formData = new FormData();
        formData.append('module', 'timeline_list');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            dispatch({ type: 'SET_TIMELINE_LIST', payload: response });
            getHistory();
        };
    };

    const getHistory = async () => {
        const formData = new FormData();
        formData.append('module', 'history');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            historyDispatch({ type: 'SET_HISTORY', payload: response[0] });
        };
    };

    useEffect(() => {
        getTimelineList();
    }, [status]);

    return (
        <div className="container-xxl">
            <PageHeader headerTitle="Tarihçe" renderRight={() => {
                return <div className="col-auto d-flex w-sm-100">
                    <button className="btn btn-dark btn-set-task w-sm-100 me-3" onClick={() => { setIsHistory(history); setLangHistory("tur"); }}>
                        <img
                            src={process.env.PUBLIC_URL + `/flags/turkey.png`}
                            style={{ width: "20px", marginRight: "5px", border: "2px solid white", borderRadius: "50%" }}
                        />
                        Türkçe Tarihçe
                    </button>
                    <button className="btn btn-dark btn-set-task w-sm-100 me-3" onClick={() => { setIsHistory(history); setLangHistory("eng"); }}>
                        <img
                            src={process.env.PUBLIC_URL + `/flags/united-kingdom.png`}
                            style={{ width: "20px", marginRight: "5px", border: "2px solid white", borderRadius: "50%" }}
                        />
                        İngilizce Tarihçe
                    </button>
                    <button className="btn btn-dark btn-set-task w-sm-100 me-3" onClick={() => { setIsHistory(history); setLangHistory("fra"); }}>
                        <img
                            src={process.env.PUBLIC_URL + `/flags/france.png`}
                            style={{ width: "20px", marginRight: "5px", border: "2px solid white", borderRadius: "50%" }}
                        />
                        Fransızca Tarihçe
                    </button>
                    <button className="btn btn-dark btn-set-task w-sm-100 me-3" onClick={() => { setIsHistory(history); setLangHistory("ger"); }}>
                        <img
                            src={process.env.PUBLIC_URL + `/flags/germany.png`}
                            style={{ width: "20px", marginRight: "5px", border: "2px solid white", borderRadius: "50%" }}
                        />
                        Almanca Tarihçe
                    </button>
                    <button className="btn btn-dark btn-set-task w-sm-100 me-3" onClick={() => { setIsHistory(history); setLangHistory("dut"); }}>
                        <img
                            src={process.env.PUBLIC_URL + `/flags/netherlands.png`}
                            style={{ width: "20px", marginRight: "5px", border: "2px solid white", borderRadius: "50%" }}
                        />
                        Felemenkçe Tarihçe
                    </button>
                    <button className="btn btn-dark btn-set-task w-sm-100" onClick={() => { setIsModal(true) }}>
                        <i className="icofont-plus-circle me-2 fs-6"></i>
                        Yeni Öğe
                    </button>
                </div>
            }} />
            <div className="row align-items-center">
                <div className="col-lg-12 col-md-12 flex-column">
                    <div className="row g-3 gy-5 py-3 row-deck">
                        {timelineList && timelineList.length > 0 &&
                            timelineList
                                .slice()
                                .sort((a, b) => new Date(Number(a.date1)) - new Date(Number(b.date1)))
                                .map((d, i) => {
                                    return <div
                                        key={"ljsdhl" + i}
                                        className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6"
                                    >
                                        <Timeline
                                            id={d.id}
                                            photoName={d.photoName}
                                            alt={d.alt}
                                            date1={d.date1}
                                            text1={d.text1}
                                            onClickEdit={() => { setIsUpdate(d); setLang("tur") }}
                                            onClickEditEng={() => { setIsUpdate(d); setLang("eng") }}
                                            onClickEditFra={() => { setIsUpdate(d); setLang("fra") }}
                                            onClickEditGer={() => { setIsUpdate(d); setLang("ger") }}
                                            onClickEditDut={() => { setIsUpdate(d); setLang("dut") }}
                                            onClickDelete={() => { setIsDelete(d); }}
                                        />
                                    </div>
                                })
                        }
                    </div>
                </div>
            </div>
            <Modal
                show={isModal}
                onHide={() => {
                    setIsModal(null);
                    setItem(null);
                    setInputLength(90);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Yeni Öğe</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="date1" className="form-label">Tarih</label>
                        <input
                            type="text"
                            className="form-control"
                            id="date1"
                            value={item && item.date1}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "date1" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "date1" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="mb-3">
                        <label htmlFor="text1" className="form-label">
                            {"Yazı "}
                            {inputLength < 20 &&
                                <span className="text-danger">{`${inputLength} harf hakkınız kaldı!`}</span>
                            }
                        </label>
                        <textarea
                            className="form-control"
                            id="text1"
                            rows="5"
                            value={item && item.text1}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "text1" ? "red" : "var(--border-color)"}` }}
                        ></textarea>
                    </div>
                    {error.field === "text1" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="mb-3">
                        <label htmlFor="alt" className="form-label">
                            {"Seo Kelimeler "}
                            <span className="text-danger">
                                (Lütfen 5 Kelimeden Fazla Yazmayınız!)
                            </span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="alt"
                            value={item && item.alt}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "alt" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "alt" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="mb-3">
                        <label htmlFor="photo" className="form-label">Fotoğraf Yükle</label>
                        <input
                            className="form-control"
                            type="file"
                            id="photo"
                            onChange={handlePhotoInput}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleSubmit()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "tur"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setInputLength(90);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="date1" className="form-label">Tarih</label>
                        <input
                            type="text"
                            className="form-control"
                            id="date1"
                            value={isUpdate && isUpdate.date1}
                            onChange={handleOnUpdateChange}
                            style={{ borderColor: `${error.field === "date1" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "date1" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="mb-3">
                        <label htmlFor="text1" className="form-label">
                            {"Yazı "}
                            {inputLength < 20 &&
                                <span className="text-danger">{`${inputLength} harf hakkınız kaldı!`}</span>
                            }
                        </label>
                        <textarea
                            className="form-control"
                            id="text1"
                            rows="5"
                            value={isUpdate && isUpdate.text1}
                            onChange={handleOnUpdateChange}
                            style={{ borderColor: `${error.field === "text1" ? "red" : "var(--border-color)"}` }}
                        ></textarea>
                    </div>
                    {error.field === "text1" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="mb-3">
                        <label htmlFor="alt" className="form-label">
                            {"Seo Kelimeler "}
                            <span className="text-danger">
                                (Lütfen 5 Kelimeden Fazla Yazmayınız!)
                            </span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="alt"
                            value={isUpdate && isUpdate.alt}
                            onChange={handleOnUpdateChange}
                            style={{ borderColor: `${error.field === "alt" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "alt" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="mb-3">
                        <label htmlFor="photo" className="form-label">Fotoğraf Yükle</label>
                        <input
                            className="form-control"
                            type="file"
                            id="photo"
                            onChange={handlePhotoUpdate}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "eng"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">İngilizce Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="textEng" className="form-label">
                            {"Yazı "}
                            {inputLength < 20 &&
                                <span className="text-danger">{`${inputLength} harf hakkınız kaldı!`}</span>
                            }
                        </label>
                        <textarea
                            className="form-control"
                            id="textEng"
                            rows="5"
                            value={isUpdate && isUpdate.textEng}
                            onChange={handleOnUpdateChange}
                            style={{ borderColor: `${error.field === "textEng" ? "red" : "var(--border-color)"}` }}
                        ></textarea>
                    </div>
                    {error.field === "textEng" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "fra"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Fransızca Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="textFra" className="form-label">
                            {"Yazı "}
                            {inputLength < 20 &&
                                <span className="text-danger">{`${inputLength} harf hakkınız kaldı!`}</span>
                            }
                        </label>
                        <textarea
                            className="form-control"
                            id="textFra"
                            rows="5"
                            value={isUpdate && isUpdate.textFra}
                            onChange={handleOnUpdateChange}
                            style={{ borderColor: `${error.field === "textFra" ? "red" : "var(--border-color)"}` }}
                        ></textarea>
                    </div>
                    {error.field === "textFra" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "ger"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Almanca Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="textGer" className="form-label">
                            {"Yazı "}
                            {inputLength < 20 &&
                                <span className="text-danger">{`${inputLength} harf hakkınız kaldı!`}</span>
                            }
                        </label>
                        <textarea
                            className="form-control"
                            id="textGer"
                            rows="5"
                            value={isUpdate && isUpdate.textGer}
                            onChange={handleOnUpdateChange}
                            style={{ borderColor: `${error.field === "textGer" ? "red" : "var(--border-color)"}` }}
                        ></textarea>
                    </div>
                    {error.field === "textGer" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "dut"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Felemenkçe Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="textDut" className="form-label">
                            {"Yazı "}
                            {inputLength < 20 &&
                                <span className="text-danger">{`${inputLength} harf hakkınız kaldı!`}</span>
                            }
                        </label>
                        <textarea
                            className="form-control"
                            id="textDut"
                            rows="5"
                            value={isUpdate && isUpdate.textDut}
                            onChange={handleOnUpdateChange}
                            style={{ borderColor: `${error.field === "textDut" ? "red" : "var(--border-color)"}` }}
                        ></textarea>
                    </div>
                    {error.field === "textDut" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={langHistory === "tur"}
                onHide={() => {
                    setIsHistory(null);
                    setLangHistory(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
                dialogClassName="modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Tarihçe Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        {
                            isHistory &&
                            <ReactQuill
                                theme="snow"
                                id="text1"
                                value={isHistory.text1}
                                onChange={handleOnHistoryChange}
                                style={{ borderColor: `${error.field === "text1" ? "red" : "var(--border-color)"}` }}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ 'size': [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                        { 'indent': '-1' }, { 'indent': '+1' }],
                                        ['link'],
                                        ['clean'],
                                        [{ 'color': [] }, { 'background': [] }],
                                    ]
                                }}
                            />
                        }
                        {error.field === "text1" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleHistory()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={langHistory === "eng"}
                onHide={() => {
                    setIsHistory(null);
                    setLangHistory(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
                dialogClassName="modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">İngilizce Tarihçe Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        {
                            isHistory &&
                            <ReactQuill
                                theme="snow"
                                id="textEng"
                                value={isHistory.textEng}
                                onChange={handleOnHistoryChange}
                                style={{ borderColor: `${error.field === "textEng" ? "red" : "var(--border-color)"}` }}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ 'size': [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                        { 'indent': '-1' }, { 'indent': '+1' }],
                                        ['link'],
                                        ['clean'],
                                        [{ 'color': [] }, { 'background': [] }],
                                    ]
                                }}
                            />
                        }
                        {error.field === "textEng" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleHistory()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={langHistory === "fra"}
                onHide={() => {
                    setIsHistory(null);
                    setLangHistory(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
                dialogClassName="modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Fransızca Tarihçe Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        {
                            isHistory &&
                            <ReactQuill
                                theme="snow"
                                id="textFra"
                                value={isHistory.textFra}
                                onChange={handleOnHistoryChange}
                                style={{ borderColor: `${error.field === "textFra" ? "red" : "var(--border-color)"}` }}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ 'size': [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                        { 'indent': '-1' }, { 'indent': '+1' }],
                                        ['link'],
                                        ['clean'],
                                        [{ 'color': [] }, { 'background': [] }],
                                    ]
                                }}
                            />
                        }
                        {error.field === "textFra" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleHistory()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={langHistory === "ger"}
                onHide={() => {
                    setIsHistory(null);
                    setLangHistory(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
                dialogClassName="modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Almanca Tarihçe Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        {
                            isHistory &&
                            <ReactQuill
                                theme="snow"
                                id="textGer"
                                value={isHistory.textGer}
                                onChange={handleOnHistoryChange}
                                style={{ borderColor: `${error.field === "textGer" ? "red" : "var(--border-color)"}` }}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ 'size': [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                        { 'indent': '-1' }, { 'indent': '+1' }],
                                        ['link'],
                                        ['clean'],
                                        [{ 'color': [] }, { 'background': [] }],
                                    ]
                                }}
                            />
                        }
                        {error.field === "textGer" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleHistory()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={langHistory === "dut"}
                onHide={() => {
                    setIsHistory(null);
                    setLangHistory(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
                dialogClassName="modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Felemenkçe Tarihçe Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        {
                            isHistory &&
                            <ReactQuill
                                theme="snow"
                                id="textDut"
                                value={isHistory.textDut}
                                onChange={handleOnHistoryChange}
                                style={{ borderColor: `${error.field === "textDut" ? "red" : "var(--border-color)"}` }}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ 'size': [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                        { 'indent': '-1' }, { 'indent': '+1' }],
                                        ['link'],
                                        ['clean'],
                                        [{ 'color': [] }, { 'background': [] }],
                                    ]
                                }}
                            />
                        }
                        {error.field === "textDut" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleHistory()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                centered
                show={isDelete}
                onHide={() => {
                    setIsDelete(null);
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Uyarı</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <div className="form-label">
                            Bu öğeyi silmek istediğinize emin misiniz?
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="danger"
                        className="me-1 text-white"
                        onClick={() => handleDelete(isDelete.id)}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Siliniyor...
                                </> :
                                <>
                                    <i className="icofont-ui-delete"></i>
                                    &ensp;
                                    Sil
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default TimelineList;